import "@fontsource/inter/variable.css"
import "./_app_global_styles.css"

import { ChakraProvider } from "@chakra-ui/react"
import AppInstrumentationProvider from "@common/components/AppInstrumentationProvider"
import SearchContextProvider from "@common/components/SearchContextProvider"
import theme from "@common/utils/theme"
import { AppProps } from "next/app"

function MyApp({ Component, pageProps }: AppProps) {
	return (
		<AppInstrumentationProvider>
			<SearchContextProvider>
				<ChakraProvider resetCSS theme={theme}>
					<Component {...pageProps} />
				</ChakraProvider>
			</SearchContextProvider>
		</AppInstrumentationProvider>
	)
}

export default MyApp
