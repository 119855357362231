import { ColorMode, extendTheme } from "@chakra-ui/react"
import { mode } from "@chakra-ui/theme-tools"

export const cssColorVar = (name: string) => `var(--chakra-colors-${name})`

export enum SectionBg {
	"blue",
	"dark",
	"light_blue",
	"light",
	"lighter",
	"white",
}
export interface ThemeColor {
	backgroundColor: string // LandingContainer background color
	sectionBackground: SectionBg // LandingSection background color
	tierName: string // tier text color
	tierSubtitle: string // tier subtitle color
	tierBullets: string // tier bullets color
	tierBg: "light" | "dark" // tier section background color
	cost: string // cost color
	costSubtitle: string // cost subtitle color
	text: string // default text color
	textSubtle: string // subtle text color
	whiteButtonBorder: string
	whiteButtonText: string
	lightButtonText: string
	blueButtonBorder: string
	blueButtonText: string
	darkButtonBorder: string
	darkButtonText: string
}

export const ThemeLight: ThemeColor = {
	backgroundColor: "bg_lighter",
	sectionBackground: SectionBg.lighter,
	tierName: "section_color_dark",
	tierSubtitle: "section_color_dark",
	tierBullets: "section_color_dark",
	tierBg: "light",
	text: "section_color_dark",
	textSubtle: "cell_light",
	cost: "section_color_dark",
	costSubtitle: "section_color_dark",
	whiteButtonBorder: "section_color_dark",
	whiteButtonText: "section_color_dark",
	lightButtonText: "white",
	blueButtonBorder: "button_blue",
	blueButtonText: "white",
	darkButtonBorder: "button_dark",
	darkButtonText: "button_dark",
}

export const ButtonStyle = {
	blue: {
		color: "white",
		backgroundColor: "cell_blue",
		borderColor: "none",
		_hover: {
			filter: "brightness(110%)",
		},
	},
	light: {
		color: "white",
		backgroundColor: "button_light",
		borderColor: "none",
		_hover: {
			filter: "brightness(110%)",
		},
	},
	white: {
		color: "section_color_dark",
		backgroundColor: "white",
		borderColor: "section_color_dark",
		_hover: {
			filter: "brightness(110%)",
		},
	},
	dark: {
		color: "white",
		backgroundColor: "button_dark",
		borderColor: "none",
		_hover: {
			filter: "brightness(110%)",
		},
	},
}

interface ThemeConfig {
	cssVarPrefix?: string
	initialColorMode?: ColorMode
	useSystemColorMode?: boolean
}

const config: ThemeConfig = {
	useSystemColorMode: false,
	initialColorMode: "dark",
}

export const colors = {
	theme_bg: "#f2f2f2",
	theme_primary: "#0370e2",
	theme_primary_active: "#017cf8",
	theme_text_bright: "#FFFFFF",
	theme_hilight: "#98989a",
	theme_hilight_dark: "#0e0e0e",
	theme_accent: "#1d1e1d",
	action_green: "#60C449",
	action_blue: "#0071E2",
	black: "#16161D",
	bg_blue: "#E8F2FD",
	bg_light_blue: "#F3F7FB",
	bg_dark: "#19191C",
	bg_dark_transparent: "rgba(25, 25, 28, .5)",
	bg_vulcan: "#19191d",
	bg_screenshot: "#1d1e1d",
	bg_light: "#1F2023",
	bg_lighter: "#F2F2F2",
	bg_quote: "#1d1d1f",
	bg_white: "#FFFFFF",
	bg_section_hilight: "#202125",
	button_blue: "#0071E2",
	button_light: "#6F6F6F",
	button_dark: "#0E0E0E",
	cell_blue: "#0071E2",
	cell_dark: "#0E0E0E",
	cell_light: "#9c9c9c",
	divider: "#292929",
	foreground_dark: "#44444c",
	foreground_neutral: "#717177",
	foreground_light: "#c4c4c4",
	foreground_white: "#ededed",
	heading_text: "#F5F5F7",
	heading_text_dark: "#0E0E0E",
	hyperlink: "#4C95F7",
	section_color: "#F5F5F7",
	section_color_light: "#98989A",
	section_color_dark: "#0E0E0E",
	section_light_border: "#c4c4c4",
	section_dark_border: "#333336",
	theme_text_dark: "#0E0E0E",
	theme_text_subtle: "#A0A7B3",
	search_border_light: "#ededed",
	search_border_dark: "#6F6F6F",
	white: "#FFFFFF",
	blue: {
		50: "#ebf8ff",
		100: "#bee3f8",
		200: "#1398f0", // Custom button color
		300: "#63b3ed",
		400: "#4299e1",
		500: "#3182ce",
		600: "#2b6cb0",
		700: "#2c5282",
		800: "#2a4365",
		900: "#1A365D",
	},
}

const fonts = { mono: `Menlo, monospace` }
fonts[
	"body"
] = `-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"`
fonts["heading"] = fonts["body"]

const fontSizes = {
	xs: "0.875rem",
	sm: "1rem",
	md: "1.125rem",
	lg: "1.25rem",
	xl: "1.5rem",
	"2xl": "1.875rem",
	"3xl": "2.25rem",
	"4xl": "3rem",
	"5xl": "3.75rem",
	"6xl": "4.5rem",
	"7xl": "6rem",
	"8xl": "8rem",
	"9xl": "9rem",
}

// const breakpoints = {
// 	xs: "28em",
// 	sm: "40em",
// 	md: "52em",
// 	lg: "64em",
// 	xl: "80em",
// }

const styles = {
	global: (props) => ({
		body: {
			// color: mode("gray.800", "whiteAlpha.900")(props),
			bg: mode("gray.100", "bg_dark")(props),
			overflowX: "hidden",
			minWidth: "400px",
		},

		a: {
			textDecoration: "underline",
		},

		"a:hover": {
			textDecoration: "none",
		},
	}),
}

// setup light/dark mode component defaults
const components = {
	// Modal: {
	// 	baseStyle: {
	// 		dialog: {
	// 			// maxWidth: [],
	// 			// minWidth: "95%",
	// 			// bg: colors.bg_vulcan,
	// 		},
	// 		// "&::before": {
	// 		// width: width + borderWidth * 2,
	// 		// height: height + borderWidth * 2,
	// 		// left: `-${borderWidth}px`,
	// 		// top: `-${borderWidth}px`,
	// 		// },

	// 		// "&::after": {
	// 		// 	top: 0,
	// 		// 	filter: `blur(${height / 6}px)`,
	// 		// },
	// 	},
	// 	// variants: {
	// 	// 	// Make a variant, we'll call it `base` here and leave it empty
	// 	// 	base: {
	// 	// 		bg: ,
	// 	// 	},
	// 	// },
	// },
	Button: {
		baseStyle: {
			// _focus: { boxShadow: "none" },
			borderRadius: 16,
		},
		variants: {
			// Make a variant, we'll call it `base` here and leave it empty
			base: {
				color: "white",
				backgroundColor: "blue.200",
				_hover: {
					bg: "#2477b3",
				},
			},
			primary: {
				color: "white",
				backgroundColor: "blue.200",
				_hover: {
					bg: "#2477b3",
				},
			},
			secondary: {
				color: colors.foreground_white,
				background: colors.foreground_dark,
				_hover: {
					background: colors.foreground_dark,
					borderColor: colors.foreground_neutral,
				},
			},
			outline: {
				// color: cssColorVar("theme_text_subtle"),
				borderColor: cssColorVar("theme_text_subtle"),
			},
		},
		defaultProps: {
			// Then here we set the base variant as the default
			variant: "base",
		},
	},
	Input: {
		variants: {
			filled: {
				field: {
					color: colors.foreground_white,
					background: colors.foreground_dark,
					border: "2px solid",
					borderColor: "transparent",
					_focus: {
						background: colors.foreground_dark,
						borderColor: colors.foreground_light,
					},
					_hover: {
						background: colors.foreground_dark,
						borderColor: colors.foreground_neutral,
					},
				},
			},
		},
	},
	Select: {
		variants: {
			filled: {
				field: {
					color: colors.foreground_white,
					background: colors.foreground_dark,
					border: "2px solid",
					borderColor: "transparent",
					_focus: {
						background: colors.foreground_dark,
						borderColor: colors.foreground_light,
					},
					_hover: {
						background: colors.foreground_dark,
						borderColor: colors.foreground_neutral,
					},
				},
			},
		},
	},
	Accordion: {
		variants: {
			bold: {
				item: {
					fontSize: "xl",
				},
				button: {
					_hover: {
						background: "bg_section_hilight",
					},
					py: 4,
					fontSize: "xl",
					fontWeight: "bold",
				},
				root: {
					borderColor: "bg_section_hilight",
				},
			},
		},
	},
	Drawer: {
		baseStyle: (props) => ({
			dialog: {
				bg: mode("white", "#141214")(props),
			},
		}),
	},
	Table: {
		baseStyle: {
			th: {
				textTransform: "none",
			},
			tr: {
				td: {
					borderColor: "theme_hilight",
					textAlign: "left",
				},
			},
		},
	},
	Switch: {
		baseStyle: {
			thumb: {
				bg: "cell_blue",
			},
			track: {
				bg: "white !important",
				boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
			},
		},
	},
	Progress: {
		baseStyle: {
			filledTrack: {
				bg: "red",
			},
		},
	},
}

const theme = extendTheme({
	config,
	colors,
	fonts,
	fontSizes,
	styles,
	components,
})

export default theme
