import { Post, SanityTag } from "@cms/components/types/Post"
import { NewsCategoryTypes } from "@cms/constants/cmsConstants"
import { getSearchResults } from "@cms/utils/sanityApi"
import debounce from "lodash.debounce"
import React, { useContext, useEffect, useMemo, useState } from "react"

export const SearchContext = React.createContext(null)
export const SearchProvider = SearchContext.Provider

interface SearchContextProviderProps {
	children: React.ReactNode
}

export interface SearchConfigT {
	categories?: NewsCategoryTypes[]
	topics?: SanityTag[]
}

/**
 * used for remembering user input when searching all content (blog, release notes, etc)
 */
const SearchContextProvider = ({ children }: SearchContextProviderProps) => {
	const [isSearching, setIsSearching] = useState<boolean>(true)
	const [searchTerms, setSearchTerms] = useState<string[]>([])
	const [searchConfig, setSearchConfig] = useState<SearchConfigT>({})
	// used for News page
	const [activeCategory, setActiveCategory] = useState<NewsCategoryTypes>(
		NewsCategoryTypes.All
	)
	// used for Docs page
	const [activeDocSection, setActiveDocSection] = useState<string>()
	const [searchResults, setSearchResults] = useState<Post[]>([])

	const runSearch = useMemo(
		() =>
			debounce((activeCategory, activeDocSection, searchTerms) => {
				if (!searchConfig.categories) {
					console.warn("No search categories specified")
					return
				}

				const searchCategories =
					activeCategory == NewsCategoryTypes.All
						? searchConfig.categories
						: [activeCategory]
				
				getSearchResults(searchCategories, activeDocSection, searchTerms).then(
					(results) => {
						setSearchResults(results)
						setIsSearching(false)
					}
				)
			}, 500),
		[setSearchResults, searchConfig.categories]
	)

	// Update search results on page load and when values change
	useEffect(() => {
		setIsSearching(true)
		runSearch(activeCategory, activeDocSection, searchTerms)

		return () => {
			runSearch.cancel()
		}
	}, [activeCategory, activeDocSection, searchTerms, runSearch])

	const value = {
		state: {
			isSearching,
			searchTerms,
			activeCategory,
			activeDocSection,
			searchResults,
			searchConfig,
		},
		actions: {
			setIsSearching,
			setSearchTerms,
			setActiveCategory,
			setActiveDocSection,
			setSearchResults,
			setSearchConfig,
		},
	}

	return <SearchProvider value={value}>{children}</SearchProvider>
}

export default SearchContextProvider

export const useSearchContext = () => useContext(SearchContext)
