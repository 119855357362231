export enum SanityCategoryTypes {
	Blog = "blog",
	ReleaseNotes = "releaseNotes",
	Support = "support",
}

export enum NewsCategoryTypes {
	All = "all",
	Blog = "blog",
	ReleaseNotes = "releaseNotes",
}

export enum CmsCategoryTypes {
	All = "all",
	Blog = "blog",
	ReleaseNotes = "releaseNotes",
	Support = "support",
}

interface CmsEntryT {
	name: string
	sanityType: string
}

interface CmsCategoryDataT {
	[key: string]: CmsEntryT
}

export const CmsCategoryData: CmsCategoryDataT = {
	[CmsCategoryTypes.All]: {
		name: "All",
		sanityType: "all",
	},
	[CmsCategoryTypes.Blog]: {
		name: "Blog",
		sanityType: "blog",
	},
	[CmsCategoryTypes.ReleaseNotes]: {
		name: "Release Notes",
		sanityType: "releaseNotes",
	},
	[CmsCategoryTypes.Support]: {
		name: "Support",
		sanityType: "support",
	},
}
