import { createClient, type ClientConfig } from "@sanity/client"
import { Post, SupportTopic } from "../components/types/Post"
import { format, parseISO } from "date-fns"
import { SanityCategoryTypes } from "../constants/cmsConstants"

export const getFormattedDate = (dateString: string) => {
	const date = parseISO(dateString)
	return format(date, "LLLL	d, yyyy")
}

const config: ClientConfig = {
	projectId: "eeotu74b",
	dataset: "production",
	useCdn: true, // set to `false` to bypass the edge cache
	apiVersion: "2023-10-25", // use current date (YYYY-MM-DD) to target the latest API version
	perspective: "published", // 'raw' | 'previewDrafts' | 'published'
}

const client = createClient(config)

// https://ux.stackexchange.com/questions/22520/how-long-does-it-take-to-read-x-number-of-characters
const charsPerReadableWord = 5
const wordsReadPerMinute = 238

/**
 * @returns a Promise containing an array of all blog posts
 */
export const getAllPostsByCategory = (
	category: SanityCategoryTypes
): Promise<Post[]> => {
	return client
		.fetch(
			`*[_type=="${category}"]{
			"slug": slug.current,
			"author": author->name,
			"authorImgUrl": author->image.asset->url,
			title,
			summary,
			"estimatedWordCount": round(length(pt::text(body)) / ${charsPerReadableWord}),
			"estimatedReadingTime": round(length(pt::text(body)) / ${charsPerReadableWord} / ${wordsReadPerMinute} ),
			"mainImageUrl": mainImage.asset->url,
			"mainImageAlt": mainImage.alt,
			"mainImageCaption": mainImage.caption,
			publishedAt,
			componentTags,
			documentType,
			documentationSection,
			priority,
			tags,
			body[]{
				..., 
				asset->{
					...,
					"_key": _id
				},
				markDefs[] {
					...,
					_type == "internalLink" => {
						...,
						"slug": @.reference-> slug
					}
				}
			},
			"headings": body[length(style) == 2 && string::startsWith(style, "h")]
		} | order(priority desc, publishedAt desc)`
		)
		.then((data) => {
			return data
		})
		.catch(console.error)
}

export const getPostBySlug = (slug: string): Promise<Post> => {
	return client
		.fetch(
			`*[slug.current == "${slug}"]{
				"slug": slug.current,
				"author": author->name,
				"authorImgUrl": author->image.asset->url,
				title,
				summary,
				"estimatedWordCount": round(length(pt::text(body)) / ${charsPerReadableWord}),
				"estimatedReadingTime": round(length(pt::text(body)) / ${charsPerReadableWord} / ${wordsReadPerMinute} ),
				"mainImageUrl": mainImage.asset->url,
				"mainImageAlt": mainImage.alt,
				"mainImageCaption": mainImage.caption,
				componentTags,
				documentType,
				documentationSection,
				tags,
				body[]{
					..., 
					asset->{
						...,
						"_key": _id
					},
					markDefs[] {
						...,
						_type == "internalLink" => {
							...,
							"slug": @.reference-> slug
						}
					}
				},
				"headings": body[length(style) == 2 && string::startsWith(style, "h")]
			}`
		)
		.then((data) => data)
		.catch(console.error)
}

/**
 * @returns a Promise containing an array of all component tags and normal tags
 */
export const getSupportTags = (): Promise<SupportTopic[]> => {
	return client
		.fetch(
			`*[_type in ["support"]] {
				componentTags,
  			tags
			}`
		)
		.then((data) => {
			return data
		})
		.catch(console.error)
}

/**
 * Returns a Promise containing the results of the current search
 * @param postTypes an array of types of posts to search for
 * @param activeDocSection documentation section to search within (may be null)
 * @param searchTerms an array of search terms to search for
 * @returns an array of Post objects
 */
export const getSearchResults = (
	postTypes: string[],
	activeDocSection: string,
	searchTerms: string[]
): Promise<Post[]> => {
	const validTerms = []
	searchTerms?.map((word) => {
		const trim = word.trim().replace(/[^'a-zA-Z0-9]/g, "")
		trim.length > 0 && validTerms.push(`"${trim}*"`)
	})
	const normalizedPostTypes = postTypes.map((type) => `"${type}"`)
	const docSection = activeDocSection ?? "*"
	let sortBy = activeDocSection  ? "order(priority desc, publishedAt desc)" : "order(title asc)"
	if(postTypes.includes("blog") || postTypes.includes("releaseNotes")) {
		sortBy = "order(publishedAt desc)"
	}

	const queryWithTerms = `*[_type in [${normalizedPostTypes}]
			&& 
				(
					title match [${validTerms}] ||
					summary[].children[].text match [${validTerms}] ||
					body[].children[].text match [${validTerms}] ||
					documentType match [${validTerms}]
				)
			&& (
				documentationSection match "${docSection}"
				)
			] {
				_type,
				"slug": slug.current,
				"author": author->name,
				"authorImgUrl": author->image.asset->url,
				title,
				summary,
				"estimatedWordCount": round(length(pt::text(body)) / ${charsPerReadableWord}),
				"estimatedReadingTime": round(length(pt::text(body)) / ${charsPerReadableWord} / ${wordsReadPerMinute} ),
				"mainImageUrl": mainImage.asset->url,
				"mainImageAlt": mainImage.alt,
				"mainImageCaption": mainImage.caption,
				publishedAt,
				componentTags,
				documentType,
				documentationSection,
				priority,
				tags,
				body[]{
					..., 
					asset->{
						...,
						"_key": _id
					},
					markDefs[] {
						...,
						_type == "internalLink" => {
							...,
							"slug": @.reference-> slug
						}
					}
				},
				"headings": body[length(style) == 2 && string::startsWith(style, "h")]
			} | ${sortBy}`

	return client
		.fetch(queryWithTerms)
		.then((data) => data)
		.catch(console.error)
}

let lastPublishedAt = ""
let lastId = ""

export const getNextSearchResults = (
	postTypes: string[],
	searchTerms: string[]
): Promise<Post[]> => {
	if (lastId === null) {
		return new Promise((resolve) => resolve([]))
	}

	const validTerms = []
	searchTerms?.map((word) => {
		const trim = word.trim().replace(/[^'a-zA-Z0-9]/g, "")
		trim.length > 0 && validTerms.push(`"${trim}*"`)
	})
	const normalizedPostTypes = postTypes.map((type) => `"${type}"`)
	const queryWithTerms = `*[_type in [${normalizedPostTypes}]
			&& 
				(
					title match [${validTerms}] ||
					summary[].children[].text match [${validTerms}] ||
					body[].children[].text match [${validTerms}] ||
					documentType match [${validTerms}]
				)
			&& 
				(
					publishedAt > '${lastPublishedAt}'
					|| (publishedAt == '${lastPublishedAt}' && _id > '${lastId}')
				)
			] {
				_id,
				_type,
				"slug": slug.current,
				"author": author->name,
				"authorImgUrl": author->image.asset->url,
				title,
				summary,
				"estimatedWordCount": round(length(pt::text(body)) / ${charsPerReadableWord}),
				"estimatedReadingTime": round(length(pt::text(body)) / ${charsPerReadableWord} / ${wordsReadPerMinute} ),
				"mainImageUrl": mainImage.asset->url,
				"mainImageAlt": mainImage.alt,
				"mainImageCaption": mainImage.caption,
				publishedAt,
				componentTags,
				tags,
			} | order(publishedAt desc) [0..100]`

	return client
		.fetch(queryWithTerms)
		.then((result) => {
			if (result.length > 0) {
				lastPublishedAt = result[result.length - 1].publishedAt
				lastId = result[result.length - 1]._id
			} else {
				lastId = null // Reached the end
			}
			return result
		})
		.catch(console.error)
}

export interface Testimonial {
	_type: string
	_createdAt: string
	user: string
	headline: string
	quote: string
	role: string
	imageUrl: string
}

/**
 * @returns a Promise containing an array of all user testimonials
 */
export const getTestimonials = (): Promise<Testimonial[]> => {
	return client
		.fetch(
			`*[_type in ["testimonial"]] {
				_type,
				_createdAt,
				user,
				headline,
				quote,
				role,
				"imageUrl": image.asset->url
			} | order(_createdAt asc)`
		)
		.then((data) => {
			return data
		})
		.catch(console.error)
}