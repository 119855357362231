import Analytics from "analytics"
import { useRouter } from "next/router"
import { useCallback, useContext, useEffect, useMemo } from "react"
import React from "react"
import googleAnalytics from "@analytics/google-analytics"
import segmentPlugin from "@analytics/segment"
import { Analytics as VercelAnalytics } from "@vercel/analytics/react"

export interface TsInstrumentationContextInterface {
	registerEvent: (eventName: string, eventData?: any) => void
}

export const TsInstrumentationContext =
	React.createContext<TsInstrumentationContextInterface>(null)
export const TsInstrumentationProvider = TsInstrumentationContext.Provider

interface AppInstrumentationProviderProps {
	children: React.ReactNode
}

const AppInstrumentationProvider = ({
	children,
}: AppInstrumentationProviderProps) => {
	const router = useRouter()

	// Initialize analytics
	const analytics = useMemo(() => {
		const plugins = []

		if (process?.env?.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID) {
			plugins.push(
				googleAnalytics({
					measurementIds: [
						process?.env?.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID,
					],
				})
			)
		}

		if (process?.env?.NEXT_PUBLIC_SEGMENT_ANALYTICS_WRITE_KEY) {
			plugins.push(
				segmentPlugin({
					writeKey: process?.env?.NEXT_PUBLIC_SEGMENT_ANALYTICS_WRITE_KEY,
				})
			)
		}

		return Analytics({
			app: "ts-marketing",
			plugins,
		})
	}, [])

	// Track custom events
	const registerEvent = useCallback(
		(name: string, properties: any = {}) => {
			analytics.track(name, properties)
		},
		[analytics]
	)

	// Track page views
	useEffect(() => {
		const handleRouteChange = () => {
			analytics.page()
		}

		router.events.on("routeChangeComplete", handleRouteChange)
		return () => {
			router.events.off("routeChangeComplete", handleRouteChange)
		}
	}, [router, analytics])

	// Return instrumentation context
	return (
		<TsInstrumentationProvider
			value={{
				registerEvent,
			}}
		>
			{children}
			<VercelAnalytics />
		</TsInstrumentationProvider>
	)
}

export default AppInstrumentationProvider

export const useAppInstrumentation = () => useContext(TsInstrumentationContext)
